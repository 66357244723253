import React from "react"
import { graphql } from "gatsby"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import Header from "src/components/header"
import Footer from "src/components/footer"
import Mainsection from "src/components/section/main"
import News from "src/components/news/news"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo />
    <Header />
    <main>
      <Mainsection title="お知らせ" subtitle="natadeCOCOに関するお知らせです。">
        <News data={data} />
      </Mainsection>
    </main>
    <Footer />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query newsQuery($limit: Int = 5, $skip: Int = 0) {
    allMarkdownRemark(
      filter: {frontmatter: {type: {eq: "news"} }},
      sort: { fields: [frontmatter___date], order: DESC },
      limit: $limit,
      skip: $skip,
    ) {
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
      edges {
        node {
          id
          frontmatter {
            date
            tag
            path
            title
          }
          html
        }
      }
    }
  }
`
