import React from "react"
import Spacer from "src/components/spacer"
import Styles from "./news.module.scss"
import { Link } from "gatsby"
import { formatDate } from 'src/util/dateUtil';

const Tag = ({ tagName }) => {
  let color
  switch (tagName) {
    case "お知らせ":
      color = "is-primary"
      break;
    // ここにcaseを増やしていく
    default:
      color = "is-light"
      break;
  }
  return <div className={`tag is-rounded ${color}`}>{tagName}</div>
}

const NewsItem = ({ post, index }) => {
  const { path, date, tag, title } = post.frontmatter
  return (
    <Link to={path}>
      <div className={`box ${Styles.item}`}>
        <div className="columns">
          <div className="column is-two-fifths">
            <div className="columns is-flex-mobile">
              <div className="column is-three-fifths has-text-centered has-text-left-mobile has-text-primary has-text-weight-bold is-size-7-mobile">{formatDate(date)}</div>
              <div className="column has-text-left has-text-right-mobile is-size-7-mobile"><Tag tagName={tag}></Tag></div>
            </div>
          </div>
          <hr className="is-hidden-tablet mt-0 mr-2 mb-1 ml-2" />
          <div className="column">
            <div className="columns is-mobile is-vcentered">
              <div className="column has-text-weight-bold">{title}</div>
              <div className="is-hidden-mobile column is-narrow"><span className="icon is-small"><i className="fas fa-caret-square-right" /></span></div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

const News = ({ data }) => {
  const { edges: posts, pageInfo } = data.allMarkdownRemark
  const { currentPage, hasNextPage, hasPreviousPage, pageCount } = pageInfo
  const perPage = 5

  const prevPage = currentPage > 2 ? "/news/page/" + (currentPage - 1).toString() : "/news"
  const nextPage = "/news/page/" + (currentPage + 1).toString()

  return (
    <div className="container">
      <Spacer isHalf />

      <div className="content">
        {posts.map(({ node: post }) => (
          <div className="mb-5" key={post.id}>
            <NewsItem post={post} />
          </div>
        ))}
      </div>

      <Spacer isHalf />

      <div className="container has-text-centered">
        <Link to={prevPage}><button className="button is-small is-light is-pulled-left has-text-weight-bold" disabled={!hasPreviousPage} rel="prev">前の{perPage}件</button></Link>
        <button className="button is-small is-light is-rounded has-text-weight-bold" style={{ pointerEvents: "none" }}>{currentPage} / {pageCount}</button>
        <Link to={nextPage}><button className="button is-small is-light is-pulled-right has-text-weight-bold" disabled={!hasNextPage} rel="next">次の{perPage}件</button></Link>
      </div>
    </div>
  );
}

export default News
